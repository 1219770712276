
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { Dashboard } from "./Components/AdminPannel/Dashboard/Dashboard";
import LoginPage from "./Components/AdminPannel/Login/LoginPage";
import { VisitorRegistration } from "./Components/AdminPannel/Registration/VisitorRegistration";
import AbuDhabiPage from "./Pages/AbuDhabi";
import KenyaPage from "./Pages/KenyaLanding";
import SingaporePage from "./Pages/Singapore";
// import VisitorRegistration from "./Pages/VisitorRegistration";
import { BookingRegistration } from "./Components/AdminPannel/Registration/BookingRegistration";
import BookYourStall from "./Pages/BookYourStall";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";
import VisitorRegistrationPage from "./Pages/VisitorRegistration";
import { PrivateRoute } from "./PrivateRoute";

// TOI Visitor Registration Form
import TOIVisitorRegistrationForm from "./Components/TOIForms/VisitorRegistrationFormTOI";
// IPE Visitor Registration Form
import IPEVisitorRegistrationForm from "./Components/IPEForm/VisitorRegistrationFormIPE";
import IPFRegistrationForm from "./Components/IPFRegistration/IPFRegistrationForm";
import PrivacyPolicyKenya from "./Pages/PrivacyPolicyKenya";
import ThankyouPage from "./Pages/Thankyou/ThankyouPage";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/" component={KenyaPage} />
        <Route exact path="/singapore" component={SingaporePage} />
        <Route exact path="/jakarta" component={AbuDhabiPage} />
        <Route exact path="/belgium" component={KenyaPage} />
        <Route
          exact
          path="/visitor-registration"
          component={VisitorRegistrationPage}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route exact path="/book-your-stall" component={BookYourStall} />
        <Route exact path="/admin" component={LoginPage} />
        <Route exact path="/dashboard">
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        </Route>
        <Route exact path="/visitor-registration-list">
          <PrivateRoute>
            <VisitorRegistration />
          </PrivateRoute>
        </Route>
        <Route exact path="/booking-enquiry">
          <PrivateRoute>
            <BookingRegistration />
          </PrivateRoute>
        </Route>

        {/* TOl Form */}
        <Route
          exact
          path="/visitorregistration"
          component={TOIVisitorRegistrationForm}
        />
        {/* IPE Form */}
        <Route
          exact
          path="/visitorregistrationipe"
          component={IPEVisitorRegistrationForm}
        />
        {/* IPF Form */}
        <Route
          exact
          path="/visitorregistrationipf"
          component={IPFRegistrationForm}
        />
        <Route
          exact
          path="/privacy-policy-kenya"
          component={PrivacyPolicyKenya}
        />
        <Route
          exact
          path="/thankyou"
          component={ThankyouPage}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
