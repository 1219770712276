import "./KenyaLanding.css";
import IndLogo from "../assest/img/kenyalanding/indiapropexpologo.png";
import IntroLogo from "../assest/img/kenyalanding/Introductions-logo.png";
import ToiLogo from "../assest/img/kenyalanding/toi.png";
import KenyaBanner from "../assest/img/kenyalanding/banner/kenya-banner.png";
import Pune from "../assest/img/kenyalanding/cities/pune.png";
import Mumbai from "../assest/img/kenyalanding/cities/mumbai.png";
import Gujarat from "../assest/img/kenyalanding/cities/Gujarat.png";
import DelhiNCR from "../assest/img/kenyalanding/cities/delhi-ncr.png";
import Gurugram from "../assest/img/kenyalanding/cities/gurugram.png";
import Hyderabad from "../assest/img/kenyalanding/cities/Hyderabad.png";
import Chennai from "../assest/img/kenyalanding/cities/chennai.png";
import Bangalore from "../assest/img/kenyalanding/cities/bangalore.png";
import Dubai from "../assest/img/kenyalanding/cities/Dubai.png";
import HotIcon from "../assest/img/kenyalanding/hot.png";
import DealIcon from "../assest/img/kenyalanding/deal.png";
import GiftIcon from "../assest/img/kenyalanding/gift.png";
import Residentialimg from "../assest/img/kenyalanding/residentialimg.png";
import Plot from "../assest/img/kenyalanding/plots.png";
import Villa from "../assest/img/kenyalanding/villa.jpg";
import Commercial from "../assest/img/kenyalanding/commercial.png";
import Developers from "../assest/img/kenyalanding/developers.png";
import Projects from "../assest/img/kenyalanding/projects.png";
import Cities from "../assest/img/kenyalanding/15-cities.png";
import Gall1 from "../assest/img/kenyalanding/WhatsApp Image 2024-05-07 at 4.35.49 PM.jpeg";
import Gall2 from "../assest/img/kenyalanding/WhatsApp Image 2024-05-07 at 4.34.27 PM (1).jpeg";
import Gall3 from "../assest/img/kenyalanding/WhatsApp Image 2024-05-07 at 4.34.26 PM.jpeg";
import Gall4 from "../assest/img/kenyalanding/WhatsApp Image 2024-05-07 at 4.34.27 PM.jpeg";
import Gall5 from "../assest/img/kenyalanding/expo.png";
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import VisitorRegistrationFormIPE from "../Components/IPEForm/VisitorRegistrationFormIPE";
export default function KenyaLanding() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Open modal after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      handleShow();
    }, 2000); // 5 seconds delay

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, []);
  useEffect(() => {
    const scrollBox = document.querySelector(".seven-cards-box");

    if (window.innerWidth <= 568) {
      let scrollAmount = 0;

      const scrollInterval = setInterval(() => {
        if (scrollBox) {
          scrollBox.scrollBy({ left: 100, behavior: "smooth" });
          scrollAmount += 100;

          if (
            scrollBox.scrollLeft + scrollBox.offsetWidth >=
            scrollBox.scrollWidth
          ) {
            scrollAmount = 0;
            scrollBox.scrollTo({ left: 0, behavior: "smooth" });
          }
        }
      }, 2000);

      // Cleanup interval on unmount
      return () => clearInterval(scrollInterval);
    }
  }, []); // Run once on component mount

  return (
    <>
      <Helmet>
        <title>
          India Property Expo 2025 - Exclusive India Property Expo in Kenya
        </title>

        <meta
          name="title"
          content="India Property Expo 2025 - Exclusive India Property Expo in Kenya"
        />
        <meta
          name="description"
          content="Discover premier property deals from Pune, Mumbai, Gujrat and many more in Kenya at the."
        />
        <meta
          name="keyword"
          content="Property Expo, India Property Expo, Property Exibition, International Property Expo, Times Expo, Realty show, India Realty Show, India Property Expo, Property Developers"
        />
        <meta
          property="og:title"
          content="India Property Expo 2025 - Exclusive India Property Expo in Kenya"
        />
        <meta
          property="og:description"
          content="Discover premier property deals from Pune, Mumbai, Gujrat and many more in Kenya at the."
        />
        <meta property="og:site_name" content="India Realty Show" />
        <meta property="og:url" content="https://indiapropexpo.com/" />
        <meta
          property="og:image"
          content="https://indiapropexpo.com/static/media/indiapropexpologo.bb5ef163d51e392164ce.png"
        />

        <link rel="canonical" href="https://indiapropexpo.com/" />

        <meta
          property="twitter:title"
          content="INDIA PROPERTY EXPO 2025 - Exclusive India Property Expo in Kenya"
        />
        <meta
          property="twitter:description"
          content="Discover premier property deals from Bangalore, Chennai, Pune, Vadodara,Mumbai, Delhi, Gurugram and many more in Kenya at the."
        />
        <meta
          name="twitter:image"
          content="https://indiapropexpo.com/static/media/indiapropexpologo.bb5ef163d51e392164ce.png"
        />
      </Helmet>
      <div className="kenya-landing-page">
        <section id="navbar" className="sticky-top shadow-sm">
          <nav className="navbar navbar-expand-md navbar-white bg-white">
            <div className="container-fluid">
              <div className=" p-0">
                <a className="navbar-brand m-0 main-logo" href="./">
                  <img src={IndLogo} alt="India Realty Show Logo" />
                  <p>Kenya</p>
                </a>
              </div>
              <div className=" btn-register" onClick={handleShow}>
                Free Visitor Registration
              </div>
              {/* <div className=" p-0">
                <a className="navbar-brand m-0" href="./">
                  <img src={ToiLogo} alt="Times of India Logo" />
                </a>
              </div> */}
            </div>
          </nav>
        </section>
        <section>
          <div className="container-fluid ">
            <div className="row banner">
              <img
                src={KenyaBanner}
                className="mobile-banner img-fluid"
                alt="Kenya"
              />
              <div className="banner-content">
                <img src={IndLogo} alt="India Realty Show Logo" />

                <p>
                  <strong>OSHWAL CENTRE</strong>
                  <br />
                  GPO, Ring Rd Parklands, Nairobi, Kenya.
                </p>
                <a
                  href="https://maps.app.goo.gl/vwk4vpdG5RKwGDneA"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="fa-solid fa-location-dot"></i> Click here for
                  direction
                </a>
                <p className="mb-0 event-time">
                  <b>
                    25<sup>th</sup> & 26<sup>th</sup> Jan 2025
                  </b>
                </p>
                <p className="mb-0 mt-2">
                  {" "}
                  <b>Time: 11:00 AM to 8 PM (Sat-Sun)</b>
                </p>
                <p className="mb-0">
                  {" "}
                  <b>Free Entry</b>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="form-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 px-lg-0">
                {/* <iframe
                  src="https://indiapropexpo.com/visitorregistration"
                  className="iframe"
                  frameborder="0"></iframe> */}
                <VisitorRegistrationFormIPE />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container py-4">
            <div className="row align-items-center">
              <div className="col-12 mb-4">
                <div className="section-two-right">
                  <h2 className="heading-style">India Property Expo - Kenya</h2>
                  <h2 className="aos-init aos-animate mt-4">
                    2 Days of Incredible Deals
                  </h2>
                  <div className="project-contents-msp pb-2 aos-init aos-animate mb-3">
                    on properties from all across India
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 aos-init aos-animate">
                      <div className="single-services">
                        <div className="icon">
                          <img
                            src={HotIcon}
                            className="w-100"
                            alt="Unbeatable Deals"
                          />
                        </div>
                        <h3>Unbeatable Deals</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 aos-init aos-animate">
                      <div className="single-services">
                        <div className="icon">
                          <img
                            src={DealIcon}
                            className="w-100"
                            alt="Flexible Payment Plans"
                          />
                        </div>
                        <h3>Flexible Payment Plans</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 aos-init aos-animate">
                      <div className="single-services mb-0">
                        <div className="icon">
                          <img
                            src={GiftIcon}
                            className="w-100"
                            alt="Incredible Pre-Launch Offers"
                          />
                        </div>
                        <h3>Incredible Pre-Launch Offers</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3 mb-lg-0">
                <div className="seven-cards-box">
                  <div className="seven-card">
                    <img src={Bangalore} className="img-fluid" alt="Chennai" />
                  </div>
                  <div className="seven-card">
                    <img src={Dubai} className="img-fluid" alt="Chennai" />
                  </div>
                  <div className="seven-card">
                    <img src={Mumbai} className="img-fluid" alt="Mumbai" />
                  </div>
                  <div className="seven-card">
                    <img src={Pune} className="img-fluid" alt="Pune" />
                  </div>
                  <div className="seven-card">
                    <img src={Gujarat} className="img-fluid" alt="Gujarat" />
                  </div>
                  <div className="seven-card">
                    <img src={DelhiNCR} className="img-fluid" alt="Delhi NCR" />
                  </div>
                  <div className="seven-card">
                    <img src={Gurugram} className="img-fluid" alt="Gurugram" />
                  </div>
                  <div className="seven-card">
                    <img
                      src={Hyderabad}
                      className="img-fluid"
                      alt="Hyderabad"
                    />
                  </div>
                  <div className="seven-card">
                    <img src={Chennai} className="img-fluid" alt="Chennai" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid bg-event bg-g mb-4">
          <div className="row position-relative">
            <div className="col-12">
              <div className="hero-content">
                <h2 className="aos-init">Free Entry</h2>
                <p style={{ fontSize: "30px" }} className="aos-init">
                  Snacks & High Tea will be provided to all Registered Clients
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="features-area pt-0 section-bg-msp">
          <div className="container">
            <div className="section-title mb-3">
              <h2 className="aos-init aos-animate">
                Get Projects across <br /> Residential, Plots, Villas &amp;
                Commercial{" "}
              </h2>
              <div className="project-contents-msp aos-init aos-animate">
                Ranging from 30 lacs to 15 Crores
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 mb-4 mb-lg-0 aos-init aos-animate">
                <div className="card">
                  <a className="popup-with-form" onClick={handleShow}>
                    <div className="single-features">
                      <img src={Residentialimg} alt="residential" />
                      <div className="project-title-msp">RESIDENTIAL</div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 mb-4 mb-lg-0 aos-init aos-animate">
                <div className="card">
                  <a className="popup-with-form" onClick={handleShow}>
                    <div className="single-features">
                      <img src={Plot} alt="Plots" />
                      <div className="project-title-msp">PLOTS</div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 mb-4 mb-lg-0 aos-init aos-animate">
                <div className="card">
                  <a className="popup-with-form" onClick={handleShow}>
                    <div className="single-features">
                      <img src={Villa} alt="villa" />
                      <div className="project-title-msp">VILLA</div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 mb-4 mb-lg-0 aos-init aos-animate">
                <div className="card">
                  <a className="popup-with-form" onClick={handleShow}>
                    <div className="single-features">
                      <img src={Commercial} alt="commercial" />
                      <div className="project-title-msp">COMMERCIAL</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container-fluid bg-g mt-4">
          <div className="row align-items-center position-relative">
            <div className="col-lg-12 col-md-12">
              <div className="hero-content">
                <h1 className="aos-init aos-animate">
                  "Exclusive for Indians in Kenya"
                </h1>
                <p
                  style={{ color: "#ffcc00", fontSize: "30px" }}
                  className="aos-init aos-animate">
                  Meet Top Property Developers from across india for a 1 on 1
                  directly right here in Kenya.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="container py-4">
            <div className="row">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="developers-card">
                  <img
                    className="w-100"
                    src={Developers}
                    alt="20+ Developers"
                  />
                  <div className="card-constant  w-100">
                    <h1 className="text-center">20+</h1>
                    <p className="text-center">Developers</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="developers-card">
                  <img
                    className="w-100"
                    src={Projects}
                    alt="100 + Projects with Exclusive Deals"
                  />
                  <div className="card-constant  w-100">
                    <h1 className="text-center">100 +</h1>
                    <p className="text-center">Projects with Exclusive Deals</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="developers-card">
                  <img className="w-100" src={Cities} alt="15 + Cities" />
                  <div className="card-constant  w-100">
                    <h1 className="text-center">15 +</h1>
                    <p className="text-center">Cities</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="glimpses-area mb-4 pb-3">
          <div className="section-title">
            <h2 className="aos-init aos-animate">
              Glimpses of Previous Property Expos
            </h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-4 d-lg-block d-none">
                <img
                  src={Gall1}
                  className="w-100"
                  alt="Glimpses of Previous Property Expos"
                />
              </div>
              <div className="col-8 d-lg-block d-none">
                <div className="row">
                  <div className="col-4">
                    <img
                      className="w-100"
                      src={Gall2}
                      alt="Glimpses of Previous Property Expos"
                    />
                  </div>

                  <div className="col-4">
                    <img
                      src={Gall3}
                      className="w-100"
                      alt="Glimpses of Previous Property Expos"
                    />
                  </div>
                  <div className="col-4">
                    <img
                      src={Gall4}
                      className="w-100"
                      alt="Glimpses of Previous Property Expos"
                    />
                  </div>
                </div>
                <div className="col-12 mt-3 d-flex">
                  <img
                    className="w-100"
                    src={Gall5}
                    alt="Glimpses of Previous Property Expos"
                  />
                </div>
              </div>
            </div>
            <div className="row d-lg-none d-block">
              <div className="col-md-4 mb-3">
                <img
                  className="w-100"
                  src={Gall1}
                  alt="Glimpses of Previous Property Expos"
                />
              </div>
              <div className="col-md-4 mb-3">
                <img
                  className="w-100"
                  src={Gall2}
                  alt="Glimpses of Previous Property Expos"
                />
              </div>
              <div className="col-md-4 mb-3">
                <img
                  className="w-100"
                  src={Gall3}
                  alt="Glimpses of Previous Property Expos"
                />
              </div>
              <div className="col-md-4 mb-3">
                <img
                  className="w-100"
                  src={Gall4}
                  alt="Glimpses of Previous Property Expos"
                />
              </div>
              <div className="col-md-4 mb-3">
                <img
                  className="w-100"
                  src={Gall5}
                  alt="Glimpses of Previous Property Expos"
                />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="py-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 data-aos="zoom-in" className="aos-init aos-animate">
                  Exhibitors
                </h2>
              </div>
            </div>
            <div className="col-12">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper">
                <SwiperSlide></SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section> */}
        <footer className="bg-light py-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                <img
                  src={IndLogo}
                  className="img-fluid"
                  alt="India Realty Show Logo"
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 ps-lg-4">
                <p className="mb-0">
                  <strong>OSHWAL CENTRE</strong>
                  <br />
                  GPO, Ring Rd Parklands, Nairobi, Kenya.
                </p>

                <a
                  href="https://maps.app.goo.gl/vwk4vpdG5RKwGDneA"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="fa-solid fa-location-dot"></i> Click here for
                  direction
                </a>
              </div>
              <div className="col-md-6 col-lg-3 ps-lg-5">
                <ul className="footer-contact-links">
                  <li>
                    <a href="tel:+917880038782">
                      <i className="fa-solid fa-phone"></i> +91 78800 38782
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/+917880038782">
                      <i className="fa-brands fa-whatsapp"></i> +91 78800 38782
                    </a>
                  </li>
                  <li>
                    <a href="mailto:sales.infairs@gmail.com">
                      <i className="fa-solid fa-envelope"></i>
                      <span>sales.infairs@gmail.com</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-lg-2">
                <b>Follow Us On</b>
                <ul className="footer-contact-links mt-2">
                  <li>
                    <a
                      href="https://www.facebook.com/indiarealtyshow"
                      target="_blank">
                      <i className="fa-brands fa-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/timesofindiarealtyshow/"
                      target="_blank">
                      <i className="fa-brands fa-instagram"></i> Instagram
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                <b className="d-block">Organized By</b>
                <img
                  src={IntroLogo}
                  className="img-fluid mt-2"
                  alt="Introduction Trade Shows Logo"
                />
              </div>
              <div className="col-lg-8 mx-auto text-center">
                <p className="footer-text">
                  Note that only Resident Indians, Non Resident Indians (NRI) ,
                  People of Indian Origin ( PIO), Overseas Citizen of India
                  (OCI) & Resident Indians living in India or Abroad are Allowed
                  to buy properties in India . This site is not a part of the
                  Facebook website or Facebook Inc . Additionally this site is
                  not endorsed by Facebook or FACEBOOK, Inc. in any way.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <div className="container-fluid px-lg-4 bg-dark py-2">
          <div className="row">
            <div className="col-md-6 text-center text-lg-start text-white">
              <p className="cursive-font mb-0 pt-1">
                Come visit and buy your dream property
              </p>
            </div>
            <div className="col-md-6 text-white text-center text-lg-end">
              <a
                href="/privacy-policy-kenya"
                className="w-auto mt-2 d-inline-block text-white text-decoration-none">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className="model-cross-visitor">
            <Modal.Title>Free Visitor Registration</Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-cross-visitor ipe-form-modal p-0">
            {/* <iframe
              src="https://indiapropexpo.com/visitorregistrationipe"
              className="iframe-model"
              frameborder="0"></iframe> */}
            <VisitorRegistrationFormIPE />
          </Modal.Body>
        </Modal>
        <div className="fixed-bottom d-block bg-white p-2 d-lg-none">
          <div className=" btn-register w-100" onClick={handleShow}>
            Free Visitor Registration
          </div>
        </div>
      </div>
    </>
  );
}
