import Notiflix from 'notiflix'
import React, { useEffect, useState } from 'react'
import { TfiReload } from 'react-icons/tfi'
import PostApiCall from '../AdminPannel/Api/PostApi'

export default function StallBooking() {
	const [stallId, setStallId] = useState(null)
	const [companyName, setCompanyName] = useState("")
	const [contactPerson, setContactPerson] = useState("")
	const [mobile, setMobile] = useState("")
	const [email, setEmail] = useState("")
	const [location, setLocation] = useState("")
	const [msg, setMsg] = useState("")
	const [events, setEvents] = useState([]) // to track api data from events
	const [captcha, setCaptcha] = useState("")
	const [eventId, setEventId] = useState(null);
	const [venue, setVenue] = useState("");
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("")
	const [site, setSite] = useState("")

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const numberRegex = /^[0-9]+$/;


	useEffect(() => {
		getEvents();
	}, [])

	function formatDateRange(startDate, endDate) {
		// Convert the start date string to a Date object
		const start = new Date(startDate);

		// Convert the end date string to a Date object
		const end = new Date(endDate);

		// Get the day, month, and year components for start and end dates
		const startDay = start.getUTCDate();
		const startMonthIndex = start.getUTCMonth();
		const startYear = start.getUTCFullYear();

		const endDay = end.getUTCDate();
		const endMonthIndex = end.getUTCMonth();
		const endYear = end.getUTCFullYear();

		// Array of month names
		const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		// Format the start date
		const formattedStartDate = `${startDay}th`;

		// Format the end date
		const formattedEndDate = `${endDay}th`;

		// Format the month and year
		const formattedMonthYear = `${months[startMonthIndex]} ${startYear}`;

		// Format the date range
		const formattedDateRange = `${formattedStartDate} to ${formattedEndDate} ${months[startMonthIndex]} ${startYear}`;

		// Return the formatted date range
		return formattedDateRange;
	}

	const getEvents = () => {
		PostApiCall.postRequest(
			{
				wherecaluse: "",
			},
			"GetEvents"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status === 200 || results.status === 201) {
					setEvents(obj.data);
					Notiflix.Loading.remove();
				} else {
					Notiflix.Notify.failure("error occurred!");
					Notiflix.Loading.remove();
				}
			})
		);
	}

	const isEmailValid = (email) => {
		return emailRegex.test(email)
	}

	const isPhoneValid = (phone) => {
		return numberRegex.test(phone)

	}

	const handleValidateForm = () => {
		if (companyName == "") {
			Notiflix.Notify.failure("Company name is required. Please enter the company name.")
			return
		}
		if (contactPerson == "") {
			Notiflix.Notify.failure("Contact person name is required. Please enter the contact person name.")
			return
		}
		if (mobile == "") {
			Notiflix.Notify.failure("Mobile number is required. Please enter the mobile numer.")
			return
		} else if (!isPhoneValid(mobile)) {
			Notiflix.Notify.failure("Invalid Mobile Number")
			return
		}
		if (email == "") {
			Notiflix.Notify.failure("Email is required. Please enter an email address.")
			return
		} else if (!isEmailValid(email)) {
			Notiflix.Notify.failure("Invalid email. Please enter a valid email.")
			return
		}

		if (location == "") {
			Notiflix.Notify.failure("Location is required. Please enter a location.")
			return
		} if (eventId == null) {
			Notiflix.Notify.failure("Please select an event from the dropdown.")
			return
		} if (msg == "") {
			Notiflix.Notify.failure("The message cannot be left blank. Please enter some content.")
			return
		}
		handleFormSubmit()
	}

	const handleRestForm = () => {
		setCompanyName("")
		setContactPerson("")
		setMobile("")
		setEmail("")
		setLocation("")
		setEventId(null)
		setMsg("")
		setEvents([])
	}

	const handleFormReset = () => {
		window.location.reload()
	}

	const handleFormSubmit = () => {
		Notiflix.Loading.dots("Please Wait")
		// validation first
		const formattedDateRange = formatDateRange(startDate, endDate);

		PostApiCall.postRequest({
			id: stallId,
			eventid: eventId,
			companyname: companyName,
			message: msg,
			name: contactPerson,
			location: location,
			venue: venue,
			mobile: mobile,
			date: formattedDateRange,
			site: site,
			email: email
		}, "UpdateStallBooking").then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Notify.success("Form Submitted Succesfully")
					handleFormReset()
					Notiflix.Loading.remove()
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure("Something Went Wrong!");
				}
			})
		);

	}

	return (
		<>
			<div className="border p-3 w-100 visitor-registration-form">
				{/* <div className="main-heading main-heading-left">
                <h2>Kindly Register Below - Free Registration!</h2>
                <p>Snacks & High Tea for all Registered Clients</p>
              </div> */}
				<div className="row">
					<div className="col-lg-6 mb-3">
						<input
							type="text"
							class="form-control"
							placeholder="Company Name *"
							value={companyName}
							onChange={(e) => setCompanyName(e.target.value)}
						/>
					</div>
					<div className="col-lg-6 mb-3">
						<input
							type="text"
							class="form-control"
							placeholder="Contact Person *"
							value={contactPerson}
							onChange={(e) => setContactPerson(e.target.value)}
						/>
					</div>
					<div className="col-lg-6 mb-3">
						<input
							type="text"
							class="form-control"
							placeholder="Mobile Number *"
							value={mobile}
							onChange={(e) => setMobile(e.target.value)}
						/>
					</div>
					<div className="col-lg-6 mb-3">
						<input
							type="text"
							class="form-control"
							placeholder="Email ID *"
							value={email}
							onChange={((e) => setEmail(e.target.value))}
						/>
					</div>
					<div className="col-lg-6 mb-3">
						<input
							type="text"
							class="form-control"
							placeholder="Client Location *"
							value={location}
							onChange={(e) => setLocation(e.target.value)}
						/>
					</div>
					<div className="col-lg-6 mb-3">
						<select class="form-select"

							onChange={(e) => {
								setEventId(e.target.value)
								const eventData = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute('data-event'));
								setVenue(eventData.fld_venue)
								setStartDate(eventData.fld_Start_Date)
								setEndDate(eventData.fld_End_date)
								setSite(eventData.fld_location)
							}
							}
						>
							<option selected disabled>Select Event *</option>
							{events.map((event) => (
								<option value={event.fld_event_id} data-event={JSON.stringify(event)}>{event.fld_location}</option>
							))}
						</select>
					</div>

					<div className="col-lg-12 mb-3">
						<textarea
							class="form-control"
							placeholder="Message *"
							value={msg}
							onChange={(e) => setMsg(e.target.value)}
							rows={5} />
					</div>
					<div>
						<button className="theme-btn"
							onClick={() => handleValidateForm()}
						>
							Book Your Stall Now
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
