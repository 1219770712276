import React from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import "./SideNavbar.css";

import { BsFillBoxSeamFill, BsFillHouseDoorFill, BsPersonLinesFill } from "react-icons/bs";
import { Link } from "react-router-dom";


export const SideNavbar = (props) => {
    return (
        <>
            <div className="sidenavbar">
                <Sidebar collapsed={props.collapsed}>
                    <Menu>
                        <a href="/dashboard">
                            <MenuItem className="sidebar-menuitem">
                                <BsFillHouseDoorFill className="sidebar-icon" /> Dashboard
                            </MenuItem>
                        </a>
                        <a href="/booking-enquiry">
                            <MenuItem className="sidebar-menuitem">
                                <BsPersonLinesFill className="sidebar-icon" /> Booking Enquiry
                            </MenuItem>
                        </a>

                        <a href="/visitor-registration-list">
                            <MenuItem className="sidebar-menuitem">
                                <BsFillBoxSeamFill className="sidebar-icon" /> Visitor Registration
                            </MenuItem>
                        </a>
                    </Menu>
                </Sidebar>
            </div>
        </>
    );
};
