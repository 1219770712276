import { useParams } from "react-router-dom";
import PostApiCall from "../AdminPannel/Api/PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import "./VisitorRegistrationFormIPE.css";
import { Radio } from "antd";
import { Select } from 'antd';


export default function VisitorRegistrationFormIPE() {
  const { Option } = Select;
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();

  const [visitorId, setVisitorId] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const [eventName, setEventName] = useState("");

  const [villa, setVilla] = useState(false);
  const [plots, setPlots] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const numberRegex =
    /^\+?(\d{1,3})?[\s\-]*\(?\d{1,4}\)?[\s\-]*\d{1,4}[\s\-]*\d{1,4}$/;

  const [VisitingDate, setVisitingDate] = useState("");
  const [VisitingDateValue, setVisitingDateValue] = useState([
    { Key: 1, Value: "01-06-2024" },
    { Key: 2, Value: "02-06-2024" },
  ]);
  const [residance, setResidence] = useState("")
  const [propertyCity, setPropertyCity] = useState("")
  // **** Number of person visiting ****
  const [VisitingPerson, setVisitingPerson] = useState("");
  const [VisitingPersonValue, setVisitingPersonValue] = useState([
    { Key: 1, Value: "One" },
    { Key: 2, Value: "Two" },
    { Key: 3, Value: "Three" },
    { Key: 4, Value: "Four" },
    { Key: 5, Value: "Five" },
    { Key: 6, Value: "Six" },
    { Key: 7, Value: "Seven" },
    { Key: 8, Value: "Eight" },
    { Key: 9, Value: "Nine" },
    { Key: 10, Value: "Ten" },
  ]);

  const onChangeResidant = (e) => {
    setResidence(e.target.value);
  };
  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };

  const isPhoneValid = (phone) => {
    return numberRegex.test(phone);
  };

  const handleValidateForm = () => {
    if (name == "") {
      Notiflix.Notify.failure("Name is required. Please enter your name.");
      return;
    }
    if (mobile == "") {
      Notiflix.Notify.failure(
        "Mobile number is required. Please enter your mobile number."
      );
      return;
    } else if (!isPhoneValid(mobile)) {
      Notiflix.Notify.failure("Invalid Mobile Number");
      return;
    }
    if (email == "") {
      Notiflix.Notify.failure("Email is required. Please enter your Email.");
      return;
    } else if (!isEmailValid(email)) {
      Notiflix.Notify.failure("Invalid email. Please enter a valid email.");
      return;
    }
    if (propertyCity == "") {
      Notiflix.Notify.failure('Please select city.');
      return;
    }
    if (residance == "") {
      Notiflix.Notify.failure("Please choose property purchase eligibility.");
      return;
    }

    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    Notiflix.Loading.dots("Please Wait...");
    PostApiCall.postRequest(
      {
        // visitorid: visitorId,
        // mailtype: mailtype,
        // mail: "html",
        // clientid: clientid,
        visitorname: name,
        email: email,
        mobile: mobile,
        natioanlity: residance,
        selectedCity: propertyCity,
        // message: msg,
        // residentials: residential,
        // plots: plots,
        // villa: villa,
        // commercial: commercial,
        // DayofAttending: VisitingDate,
        // person: VisitingPerson,
        // eventname: "Abu Dhabi",
        website: "https://www.indiapropexpo.com/",
      },
      "UpdateVisitorRegistrationIPE"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Form Submitted Succesfully");
          window.parent.location.href =
            "https://www.indiapropexpo.com/thankyou";
          setName("");
          setEmail("");
          setMobile("");
          setResidence("");
          setPropertyCity("");
        }
      })
    );
  };

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      //   useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);

  return (
    <>
      <div className="p-3 w-100 visitorformbg visitorformbg-ipe">
        <div className="main-heading main-heading-left">
          <h2>Kindly Register Below - Free Visitor Registration!</h2>
          <p>
            <strong>Free Entry-</strong> Snacks & High Tea provided to all
            Registered Clients
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-sm-3 mb-2">
            <label>Name *</label>
            <input
              type="text"
              className="form-control visitor-input py-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-3 mb-2">
            <label>Mobile Number *</label>
            <input
              type="text"
              className="form-control visitor-input"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-3 mb-2">
            <label>Email *</label>
            <input
              type="email"
              className="form-control visitor-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-3 mb-2">
            <label>Select a City *</label>
            <Select
              showSearch
              className="visitor-input form-control"
              placeholder="Select a city"
              optionFilterProp="children"
              onChange={(value) => setPropertyCity(value)}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Dubai">Dubai</Option>
              <Option value="Mumbai">Mumbai</Option>
              <Option value="Pune">Pune</Option>
              <Option value="Goa">Goa</Option>
              <Option value="Gujarat">Gujarat</Option>
              <Option value="Delhi NCR">Delhi NCR</Option>
              <Option value="Gurugram">Gurugram</Option>
              <Option value="Hyderabad">Hyderabad</Option>
              <Option value="Bangalore">Bangalore</Option>
              <Option value="Chennai">Chennai</Option>
            </Select>
          </div>
          <div>
            Property Purchase Eligibility *
          </div>
          <Radio.Group onChange={onChangeResidant} value={residance}>
            <Radio value={"NRI"}>NRI</Radio>
            <Radio value={"PIO"}>PIO</Radio>
            <Radio value={"OCI"}>OCI</Radio>
            <Radio value={"INDIAN"}>INDIAN</Radio>
          </Radio.Group>
          <small>
            *Note that only Indians, Non Resident Indians (NRI's), People of
            Indian Origin (PIO's), Overseas Citizen of India (OCI's) & Resident
            Indians living in India or Abroad are Allowed to buy properties in
            India.
          </small>
          <div className="mt-1 mb-0 mb-sm-1 pb-3 pb-sm-2 col-sm-3 align-self-sm-end">
            <button
              type="button"
              className="visitor-btn"
              onClick={() => handleValidateForm()}>
              Register as a Visitor
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
