import moment from "moment";
import Notiflix from "notiflix";
import React, { useState } from "react";
import loginlogo from "../../../assest/img/nriniveshpropertyexpo-logo.png";
// import { apiError, loginUser } from "../../store/actions";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import PostApiCall from "../Api/PostApi";
import "./Login.css";
export default function LoginPage() {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const OnLogin = () => {
        if (
            userName != "" &&
            password != "" &&
            userName != null &&
            password != null
        ) {
            Notiflix.Loading.dots();
            PostApiCall.postRequest(
                {
                    email: userName,
                    password: password,
                    action: "login",
                    actiondate: new Date(),
                },
                "AuthenticateUser"
            ).then((result) =>
                result.json().then((obj) => {
                    if (result.status == 200 || result.status == 201) {
                        localStorage.setItem("LoginDetail", JSON.stringify(obj.data[0]));
                        sessionStorage.setItem("access", obj.token);
                        window.location.href = "/dashboard"
                    } else {
                        Notiflix.Loading.remove();
                        Notiflix.Notify.failure("Authentication Failed!");
                    }
                })
            );
        } else {
            Notiflix.Notify.failure("Please Enter Username and Password!");
        }
    }
    return (
        <React.Fragment>
            <div className="account-pages">
                <Container fluid className="main-img">
                    <Row className="justify-content-center align-items-center card-align">
                        <Col md={7} lg={5} xl={4} className="login-card">
                            <Card className="overflow-hidden login-card-shadow">
                                <Card.Body className="pt-0 mb-4">
                                    <div className="text-center mt-5 border-bottom">
                                        <img src={loginlogo} className="img-fluid w-50"></img>
                                    </div>
                                    <div className="p-2 mt-2">
                                        {/* <Form> */}
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email"
                                                value={userName}
                                                onChange={(e) => {
                                                    setUserName(e.target.value)
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                }}
                                                type="password" />
                                        </Form.Group>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p className="mb-0">Forgot Password?</p>
                                            <Button variant="primary" type="submit"
                                                onClick={() => {
                                                    OnLogin()
                                                }}
                                            >
                                                Sign in
                                            </Button>
                                        </div>
                                        {/* </Form> */}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <div className="row login-footer">
                        <div className="col-md-6">
                            <p className="mb-0 footer">
                                Powered by{" "}
                                <a
                                    href="http://globaltrendz.com/"
                                    target="_blank">
                                    {" "}
                                    Global Trendz.
                                </a>
                            </p>
                        </div>
                        <div className="col-md-6 text-end">
                            <p className="mb-0 footer">
                                Copyright @ {moment().format("YYYY")} NRI Nivesh Property Expo. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}