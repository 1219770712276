import moment from "moment";
import organizerimg from "../../assest/img/mudra.png";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import "./Footer.css";
export default function Footer() {
  return (
    <>
      <footer className="bg-light py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
              <img src={organizerimg} className="img-fluid" />
            </div>
            <div className="col-md-6 col-lg-3 ps-lg-5">
              <b>NRI Nivesh Property Expo</b>
              <p>
                115. Morya Estate, New Link Road, <br />
                Andheri West, Mumbai 400053, INDIA.
              </p>
            </div>
            <div className="col-md-6 col-lg-3">
              <ul className="footer-contact-links">
                <li>
                  <a href="tel:+919322228089">
                    <IoCall />
                    +91 93222 28089
                  </a>
                </li>
                <li>
                  <a href="mailto:sales.infairs@gmail.com">
                    <IoMdMail />
                    sales.infairs@gmail.com
                  </a>
                </li>
                <li>
                  <a href="https://wa.me/+919322228089" target="_blank">
                    <FaWhatsapp />
                    +91 93222 28089
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3">
              <b>Follow Us On</b>
              <ul className="footer-contact-links mt-2">
                <li>
                  <a
                    href="https://www.facebook.com/nriniveshpropertyexpo"
                    target="_blank">
                    <FaFacebook />
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nriniveshpropertyexpo/?hl=hi"
                    target="_blank">
                    <FaInstagram />
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-8 mx-auto text-center">
              <p className="footer-text">
                Note that only Resident Indians, Non Resident Indians (NRI) ,
                People of Indian Origin ( PIO), Overseas Citizen of India (OCI)
                & Resident Indians living in India or Abroad are Allowed to buy
                properties in India . This site is not a part of the Facebook
                website or Facebook Inc . Additionally this site is not endorsed
                by Facebook or FACEBOOK, Inc. in any way.{" "}
              </p>
              <a href="/privacy-policy" className="w-auto mt-2 d-inline-block">
              Privacy Policy
            </a>
            </div>
            
          </div>
        </div>
      </footer>
      <div className="container-fluid px-lg-4 bg-dark py-2">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-12 col-lg-6 text-lg-start text-center text-white">
            Design
            <a
              href="https://globaltrendz.com/"
              target="blank"
              className="text-white">
              &nbsp; Global Trendz
            </a>
          </div>
          <div className="col-12 col-lg-6 text-lg-end text-center text-white">
            <p className="mb-0">
              Copyright &copy; {moment().format("YYYY")} NRI Nivesh Property
              Expo. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
