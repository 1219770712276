import { Helmet } from "react-helmet";
import AbuDhabiBanner from "../assest/img/home-banner/setiabudi-jakarat-banner.jpg";
import AbuDhabiMobileBanner from "../assest/img/home-banner/setiabudi-jakarat-mobile-banner.jpg";
import Commercial from "../assest/img/singapore-page/commercial.png";
import Deal from "../assest/img/singapore-page/deal.png";
import Developers from "../assest/img/singapore-page/developers.png";
import Major from "../assest/img/singapore-page/major.png";
import Map from "../assest/img/singapore-page/map.png";
import Payment from "../assest/img/singapore-page/money.png";
import Offers from "../assest/img/singapore-page/offers.png";
import Plots from "../assest/img/singapore-page/plots.png";
import Projects from "../assest/img/singapore-page/projects.png";
import Residential from "../assest/img/singapore-page/residential.png";
import Villas from "../assest/img/singapore-page/villas.png";
import Footer from "../Components/Footer/Footer";
import VisitorRegistrationForm from "../Components/Forms/VisitorRegistrationForm";
import WebsiteNavbar from "../Components/WebsiteNavbar/WebsiteNavbar";
import pdf from "../assest/pdf/Upcoming Events Participation Proposal.pdf"
import { FaFilePdf } from "react-icons/fa";
export default function AbuDhabiPage() {
  return (
    <div className="">
      <Helmet>
        <title>
          Unveiling the Best: Indian Property Exhibition in Abu Dhabi        </title>
        <meta
          name="description"
          content="Looking to invest in Indian real estate as an NRI from Abu Dhabi? Discover fantastic property deals all over India on our website"
        />
        <meta name="keywords" content="Abu Dhabi, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Commercial Property, Residential Apartment, Villas and houses, Plots and Lands" />

        <meta
          property="og:title"
          content="Unveiling the Best: Indian Property Exhibition in Abu Dhabi"
        />
        <meta
          property="og:description"
          content="Looking to invest in Indian real estate as an NRI from Abu Dhabi? Discover fantastic property deals all over India on our website"
        />
        <meta property="og:url" content="https://indiapropexpo.com/jakarta
" />
        <link rel="canonical" href="https://indiapropexpo.com/jakarta" />        <meta property="og:image" content=" https://indiapropexpo.com/static/media/2.00936d788bc16a8af1fe.png" />
        <meta
          name="twitter:title"
          content="Unveiling the Best: Indian Property Exhibition in Abu Dhabi"
        />
        <meta
          name="twitter:description"
          content="Looking to invest in Indian real estate as an NRI from Abu Dhabi? Discover fantastic property deals all over India on our website
"
        />
        <meta name="twitter:image" content=" https://indiapropexpo.com/static/media/2.00936d788bc16a8af1fe.png" />
      </Helmet>
      <WebsiteNavbar />
      <div>
        <img src={AbuDhabiBanner} className="img-fluid d-none d-lg-block" alt="Abu Dhabi Banner" />
        <img
          src={AbuDhabiMobileBanner}
          className="img-fluid d-block d-lg-none"
          alt="Abu Dhabi Banner"
        />
      </div>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading">
              <h1>
                Are you an NRI from Jakarta looking to invest in Indian Real
                Estate?
              </h1>
              <p>Get Incredible Deals on Properties Across India</p>
            </div>
            {/* <p className="text-justify">
              Indian expatriate community is the largest ethnic community in UAE
              constituting roughly 30 per cent of the country’s population. The
              number of resident Indian Nationals is estimated to be 3.5 million
              in 2021 as per UAE records. Approx. 20% of the diaspora is in the
              Emirate of Abu Dhabi and rest in 6 Northern Emirates including
              Dubai. Though most of the Indians living here are employed, about
              10 per cent of the Indian population constitutes dependent family
              members. Keeping in line with the diverse linguistic and cultural
              ethos of India, there are various linguistic/regional associations
              for social and cultural activities. All sections of the Indian
              community are culturally very active.
            </p> */}
            {/* <p className="text-justify">The maximum Indian Population is in the business of mining, hotels, and trading in Indonesia leading to high ticket-size property sales. Indians in Jakarta have high purchasing power and a preference for property investment in India. Property investment is viewed as a long-term investment for good returns and personal use. According to the Indian Ministry of External Affairs, there are about 100,000 people of Indian Origin living & working in Indonesia as of January 2013. Almost half of the Indian Community is now Jakarta-based with a perfect blend of employed & self-employed Indians. Indians in Indonesia are one of the wealthiest and most well-off communities. 85-90% of Indonesian Indians are connected to India with frequent family visits and business interests.</p> */}
            {/* <b className="text-start w-100 d-block">There are various Community Associations in Abu Dhabi:</b> */}
            {/* <p className="text-justify">
              Abu Dhabi Karnataka Sangha, Abu Dhabi Tamil Sangam, Telegu
              Samajam, Maharashtra Mandal of Abu Dhabi, Indian Social and
              Cultural Centre, Abu Dhabi Malayalee Samajam, Bihar & Jharkhand
              Samaj, Abu Dhabi Sindhi Association, Abu Dhabi Sikh Khalsa.
            </p> */}
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-lg-8 text-justify">
            <b>INDONESIA - Total Indian Population: 2.50.000 ( 2.5% )</b>
            <p>The maximum Indian Population is in the business of mining, hotels, and trading in Indonesia leading to high ticket-size property sales. Indians in Jakarta have high purchasing power and a preference for property investment in India. Property investment is viewed as a long-term investment for good returns and personal use. According to the Indian Ministry of External Affairs, there are about 100,000 people of Indian Origin living & working in Indonesia as of January 2013. Almost half of the Indian Community is now Jakarta-based with a perfect blend of employed & self-employed Indians. Indians in Indonesia are one of the wealthiest and most well-off communities. 85-90% of Indonesian Indians are connected to India with frequent family visits and business interests.</p>
            <a href={pdf} className="theme-btn" target="_blank">Download <FaFilePdf />
            </a>
            {/* <p className="mb-0">
              Our exhibitions cater to NRIs like you, providing best property
              deals in luxurious villas, commercial spaces, residential
              properties and plots, we have something for everyone. Explore our
              selection of properties and take advantage of exclusive deals
              designed with NRIs in mind. Whether you’re looking for a lucrative
              investment opportunity or a dream home in India, we’re here to
              help you every step of the way. Let us be your trusted partner in
              supporting the Indian real estate from Abu Dhabi.
            </p>
            <p>
              Explore an extensive lineup of top real estate developers at our
              exhibition, representing major cities across India. With a wide
              array of projects on display, you’ll find the perfect property to
              suit your needs. From luxurious residential complexes to lucrative
              commercial spaces, our exhibitors offer the best deals for
              discerning investors. Visit NRI Nivesh India Property Expo and
              discover the ideal investment opportunity for you!
            </p> */}
            <div className="events-projects-img d-flex mt-3 justify-content-between">
              <img src={Developers} className="img-fluid" alt="Developers, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Abu Dhabi" />
              <img src={Major} className="img-fluid" alt="Major cities, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Abu Dhabi" />
              <img src={Projects} className="img-fluid" alt="Projects, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Abu Dhabi" />
            </div>
          </div>
          <div className="col-lg-4 events-projects-right-img">
            <div className="row">
              <div className="col-md-6 mb-3 pe-lg-2">
                <img src={Residential} className="img-fluid" alt="Residential Apartments, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Abu Dhabi" />
              </div>
              <div className="col-md-6 mb-3 ps-lg-2">
                <img src={Villas} className="img-fluid" alt="Villas and Houses, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Abu Dhabi" />
              </div>
              <div className="col-md-6 mb-3 mb-lg-0 pe-lg-2">
                <img src={Plots} className="img-fluid" alt="Plots and Lands, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Abu Dhabi" />
              </div>
              <div className="col-md-6 mb-3 mb-lg-0 ps-lg-2">
                <img src={Commercial} className="img-fluid" alt="Commercial Property, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Abu Dhabi" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="singapore-parallax-section abu-dhabi-parallax-section py-5 mb-4">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 text-white text-center">
              <h2>Exclusive Exhibition for Indians in Jakarta</h2>
              <h3>
                Meet the Indian Property Developers 1 on 1 directly right hear
                in Jakarta
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="main-heading main-heading-left">
              <h2>From Rs 30 Lacs to Rs. 15 Crores</h2>
              <p>Get Incredible Deals From Following Cities</p>
            </div>

            <img src={Map} className="img-fluid mt-4" alt="map" id="visitor-registration" />
          </div>
          <div className="col-lg-6">
            <VisitorRegistrationForm />
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-3 text-center">
            <div>
              <h3 className="mb-0">2 Days Of Incredible Deals</h3>
              <p>on properties from all across India</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Deal} className="img-fluid" alt="Deal" />
              <h4>Unbeatable Deals</h4>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Payment} className="img-fluid" alt="Payment" />
              <h4>Flexible Payment Plans</h4>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Offers} className="img-fluid" alt="Offers" />
              <h4>Incredible Pre-Launch Offers</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom d-block d-md-none p-2 bg-white">
        <a href="#visitor-registration" className="theme-btn text-center w-100">
          Visitor Registration
        </a>
      </div>
      <Footer />
    </div>
  );
}
