import Footer from "../Components/Footer/Footer";
import WebsiteNavbar from "../Components/WebsiteNavbar/WebsiteNavbar";

export default function PrivacyPolicyPage() {
  return (
    <>
      <WebsiteNavbar />
      <div className="container-fluid privacy-policy-page">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">Privacy Policy</h1>
            <p>
              The NRI Nivesh India Property Expo privacy policy explains how we
              collect, use, and protect your personal information when you
              attend our property exhibition events.
            </p>
            <b>What Information We Collect</b>
            <p>We collect the following types of personal information:</p>
            <ul>
              <li>
                <b>Contact Information:</b> Your name, email address, phone
                number, and address.
              </li>
              <li>
                <b>Registration Information:</b> Information you provide when
                signing up for an event.
              </li>
              <li>
                <b>Feedback and Communication:</b> Your comments, questions, and
                other correspondence with us.
              </li>
            </ul>
            <b>How We Use Your Information</b>
            <p>We use your information to:</p>
            <ul>
              <li>Organize and manage your participation in our events.</li>
              <li>Provide you with updates and event invitations.</li>
              <li>Improve our services based on your feedback.</li>
              <li>Respond to your inquiries.</li>
            </ul>
            <b>Sharing Your Information</b>
            <p>We may share your information with:</p>
            <ul>
              <li>
                <b>Event Partners:</b> To give you information about properties
                and services.
              </li>
              <li>
                <b>Service Providers:</b> Who help us manage our events and
                services.
              </li>
              <li>
                <b>Authorities:</b> If required by law.
              </li>
            </ul>
            <b>Data Retention</b>
            <p>
              We keep your information as long as needed for our services and to
              meet legal requirements.
            </p>
            <b>Security</b>
            <p>
              We take steps to protect your information from unauthorized access
              or misuse.
            </p>
            <b>Changes to This Privacy Policy</b>
            <p>
              We may update this policy. Any changes will be posted on our
              website.
            </p>
            <b>Contact Us</b>
            <p>
              If you have questions about this privacy policy, please contact us
              at:
            </p>
            <ul>
              <li>
                Email:{" "}
                <a href="mailto:sales.infairs@gmail.com">
                  sales.infairs@gmail.com
                </a>
              </li>
              <li>
                Phone: <a href="tel:+919322228089">+91 93222 28089</a>
              </li>
              <li>
                Mailing Address:{" "} 
                <span>
                  115. Morya Estate, New Link Road, <br />
                  Andheri West, Mumbai 400053, INDIA.
                </span>
              </li>
            </ul>
            <p>
              Thank you for being part of the NRI Nivesh India Property Expo. We
              value your privacy and strive to protect your information.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
