import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BsFileEarmarkPdf } from "react-icons/bs";
import PostApiCall from "../Api/PostApi";
import { RegistrationTable } from "./RegistrationTable";
import Dropdown from "react-bootstrap/Dropdown";
import Notiflix from "notiflix";

export const VisitorRegistration = () => {
  const [visitorDetails, setVisitorDetails] = useState([]);

  //For filter data
  const [filteredVisitorDetails, setFilteredVisitorDetails] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState("");

  useEffect(() => {
    Notiflix.Loading.dots("Please Wait...");
    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "*",
      },
      "GetVisitorDetails"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          setVisitorDetails(obj.data);
          setFilteredVisitorDetails(obj.data);
        }
      })
    );
  }, []);

  // For filter data
  useEffect(() => {
    if (selectedWebsite) {
      setFilteredVisitorDetails(
        visitorDetails.filter(
          (visitor) => visitor.fld_website === selectedWebsite
        )
      );
    } else {
      setFilteredVisitorDetails(visitorDetails);
    }
  }, [selectedWebsite, visitorDetails]);

  const handleSelectWebsite = (website) => {
    setSelectedWebsite(website);
    Notiflix.Loading.dots("Please Wait...");
    setTimeout(() => {
      Notiflix.Loading.remove(); // Remove loading notification after some time
    }, 1000);
  };

  const Visitordata = {
    columns: [
      {
        label: "S No.",
        field: "SNo",
        sort: "asc",
        width: 30,
      },
      {
        label: "Visitor Name",
        field: "VisitorName",
        sort: "asc",
        width: 30,
      },
      {
        label: "Email & Mobile",
        field: "Emailandmobile",
        sort: "asc",
        width: 50,
      },
      {
        label: "Day of Attending",
        field: "DayOfAttending",
        sort: "asc",
        width: 350,
      },
      {
        label: "Looking for",
        field: "Lookingfor",
        sort: "asc",
        width: 350,
      },
      {
        label: "Need Assistance For",
        field: "needAssistancefor",
        sort: "asc",
        width: 350,
      },
      {
        label: "Message",
        field: "Message",
        sort: "asc",
        width: 150,
      },
      {
        label: "Location",
        field: "Location",
        sort: "asc",
        width: 100,
      },
    ],
    // rows: visitorDetails?.map((data, i) => {
    rows: filteredVisitorDetails?.map((data, i) => {
      return {
        SNo: i + 1,
        VisitorName: data.fld_visitor_name,
        Emailandmobile: (
          <>
            <div>{data.fld_email}</div>
            <div>{data.fld_Mobile}</div>
          </>
        ),
        DayOfAttending: data.Day_of_attending,
        Message: data.fld_message,
        Location: data.fld_location,
        Lookingfor: (
          <>
            {data.fld_residentials == "true" && (
              <p>{data.fld_residentials == "true" ? "Residential" : ""}</p>
            )}
            {data.fld_plots == "true" && (
              <p>{data.fld_plots == "true" ? "Plots" : ""}</p>
            )}
            {data.fld_villa == "true" && (
              <p>{data.fld_villa == "true" ? "Villa" : ""}</p>
            )}
            {data.fld_commercial == "true" && (
              <p>{data.fld_commercial == "true" ? "Commercial" : ""}</p>
            )}
          </>
        ),
        needAssistancefor: (
          <>
            {data.fld_india_investment == "true" && (
              <p>
                {data.fld_india_investment == "true"
                  ? "Indian Investments"
                  : ""}
              </p>
            )}
            {data.fld_taxfiling == "true" && (
              <p>
                {data.fld_taxfiling == "true"
                  ? "Tax Filing & Will in India"
                  : ""}
              </p>
            )}
          </>
        ),
        action: [<AiOutlineEye />, <BsFileEarmarkPdf />],
      };
    }),
  };
  return (
    <div className="container-fluid position-relative">
      <div className="w-100 text-end position-absolute fiter-website-name">
        <Dropdown onSelect={handleSelectWebsite}>
          <Dropdown.Toggle variant="dark" id="dropdown-basic">
            Filter by Website
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="">Show All</Dropdown.Item>
            <Dropdown.Item eventKey="https://timesrealtyexpo.com/">
              TOI
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <RegistrationTable type="Visitor Registration List" data={Visitordata} />
    </div>
  );
};
