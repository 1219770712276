import React, { useEffect, useState } from "react";
import PostApiCall from "../Api/PostApi";
import { RegistrationTable } from "./RegistrationTable";
export const BookingRegistration = () => {
    const [stallRegistration, setStallRegistration] = useState([]);
    useEffect(() => {
        PostApiCall.postRequest(
            {
                whereClause: "",
                recordCount: "*"
            },
            "GetStallRegistration"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setStallRegistration(obj.data);
                }
            })
        );
    }, [])
    const BookingEnquiry = {
        columns: [
            {
                label: "S No.",
                field: "SNo",
                sort: "asc",
                width: 30,
            },
            {
                label: "Company Name",
                field: "companyName",
                sort: "asc",
                width: 130,
            },
            {
                label: "Contact Person",
                field: "contactPerson",
                sort: "asc",
                width: 50,
            },
            {
                label: "Email & Mobile",
                field: "Emailandmobile",
                sort: "asc",
                width: 50,
            },
            {
                label: "Message",
                field: "Message",
                sort: "asc",
                width: 150,
            },
            {
                label: "Customer's Location",
                field: "Location",
                sort: "asc",
                width: 100,
            }
        ],
        rows:
            stallRegistration?.map((data, i) => {
                return (
                    {
                        SNo: i + 1,
                        companyName: data.fld_company_name,
                        contactPerson: data.fld_name,
                        Emailandmobile: <>
                            <div>{data.fld_email}</div>
                            <div>{data.fld_mobile}</div>
                        </>,
                        Message: data.fld_message,
                        Location: data.fld_location,
                    }
                )
            })
        ,
    };
    return (
        <>
            <RegistrationTable type="Booking Enquiry" data={BookingEnquiry} />
        </>
    );
};
